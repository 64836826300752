<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="medias">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form>
              <b-row>
                <b-col
                    cols="12"
                >

                  <b-button
                      class="mt-2 col-6"
                      variant="warning"
                      @click="$refs.refInputEl2.click()"
                  >
                    <input
                        ref="refInputEl2"
                        class="d-none"
                        type="file"
                        @change="select"
                    >
                    <span class="">بارگذاری</span>
                  </b-button>
                  <b-button
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      class="mt-2 mb-sm-0 ml-2 col-12 col-md-2"
                      variant="primary"
                      @click="upload"
                  >
                    ثبت
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

            <b-progress
                v-if="uploaded !== 0 && uploaded !== 100"
                :value="uploaded"
                class=" mt-2"
                max="100"
                variant="primary"
            />

            <b-alert
                :show="uploaded === 100"
                class="col-md-2 col-12"
                variant="success"
            >
              <div class="alert-body mt-2">
                <feather-icon
                    class="mr-25"
                    icon="CheckIcon"
                />
                <span class="ml-25">فایل شما آپلود شده است.</span>
              </div>
            </b-alert>
            <b-alert
                :show="errorAlert"
                class="col-md-2 col-12"
                variant="danger"
            >
              <div class="alert-body mt-2">
                <feather-icon
                    class="mr-25"
                    icon="CloseIcon"
                />
                <span class="ml-25">آپلود فایل با مشکل مواجه شد!</span>
              </div>
            </b-alert>
          </b-card>

          <div  class="w-100">
            <b-card v-if="medias.videos.length > 0">
              <b-row class="px-2">

                <h3>ویدیو</h3>

                <div v-for="(item,idx) in medias.videos" :key="idx" class="row mt-5">
                  <video
                      class="col-12"
                      controls
                      style="max-height: 30rem;"
                  >
                    <source
                        :src="'https://api.enzamode.ir/Media/Gallery/AcademyVideo/'+item.pictureUrl"
                        size=""
                        type=""
                    />
                  </video>
                  <b-button
                      v-b-modal.modal-delete
                      class="m-2"
                      variant="danger"
                      @click="showModal(item)"
                  >
                    <feather-icon class="" icon="TrashIcon" size="20"/>
                  </b-button>

  <!--                  <b-button-->
  <!--                      v-b-modal.modal-edit-name-->
  <!--                      class="m-2"-->
  <!--                      variant="primary"-->
  <!--                      @click="showChangeNameModal(item.mediaId,item.title.split('.')[0])"-->
  <!--                  >-->
  <!--                    <feather-icon icon="EditIcon" size="20"/>-->
  <!--                  </b-button>-->

  <!--                  <b-button-->
  <!--                      v-b-modal.modal-edit-priority-->
  <!--                      class="m-2"-->
  <!--                      variant="success"-->
  <!--                      @click="showChangePriorityModal(item.mediaId)"-->
  <!--                  >-->
  <!--                    <feather-icon icon="AlignCenterIcon" size="20"/>-->
  <!--                  </b-button>-->
                </div>

              </b-row>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
        id="modal-delete"
        cancelTitle="انصراف"
        centered
        ok-title="حذف"
        @ok="deleteMedia(pictureUrl)"
    >
      <span>حذف شود؟</span>
    </b-modal>
    <b-modal
        id="modal-edit-name"
        cancelTitle="انصراف"
        centered
        ok-title="ثبت"
        title="ویرایش نام فایل"
        @ok="updateMediaName"
    >
      <input v-model="newName" type="text" @keydown.enter="updateMediaName">
    </b-modal>
    <b-modal
        id="modal-edit-priority"
        cancelTitle="انصراف"
        centered
        ok-title="ثبت"
        title="ویرایش اولویت فایل"
        @ok="updateMediaPriority"
    >
      <input v-model="newPriority" type="number" @keydown.enter="updateMediaPriority">
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia, BOverlay,
  BRow, BTab, BTabs, BProgress, BModal
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from "vue-select";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ListCourseMedias, MediaDeleteByPictureUrlRequest} from "@/libs/Api/academy";

import Helper from "@/libs/Helper";

export default {
  title: 'مدیای دوره - پنل ادمین انزا مد',
  name: "ECommerceAddProductMedia",
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BProgress,
    AppCollapse,
    AppCollapseItem,
    BModal,
  },

  data() {
    return {
      // let userToken = context.$cookies.get('token');


      academyId: this.$route.params.id,
      userToken: localStorage.getItem('accessToken'),
      medias: null,
      errorAlert: false,
      overlay: true,
      deleteItem: null,
      baseURL: Helper.baseUrl,
      pictureUrl:'',
      types: [
        {name: 'دوره', value: 1},
        {name: 'لایو', value: 2},
        {name: 'فایل', value: 3},
        {name: 'پادکست', value: 4},
        {name: 'پست آموزشی', value: 5},
      ],
      file: null,
      chunks: [],
      uploaded: 0,
      namePictureURL: null,
      priorityPictureURL: null,
      newName: '',
      newPriority: '',
    };
  },
  async created() {
      await this.getProductMedias();
  },
  computed: {
    formData() {
      let formData = new FormData;

      if (this.file !== null) {
        // formData.set('is_last', this.chunks.length === 1);
        // formData.set('file', this.chunks[0], `${this.file.name}`);
        formData.set('file', this.file, `${this.file.name}`);
      }
      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: 'https://api.enzamode.ir/api/Academy/UploadFile?objectId=' + this.academyId + '&productTypes='+ 2 ,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': this.userToken,
        },
        onUploadProgress: progressEvent => {
          this.uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      };
    },
  },
  methods: {
    async getProductMedias() {
      let _this = this;
      _this.overlay = true

      let listCourseMedias = new ListCourseMedias(_this);
      let data = {
        objectId:this.$route.params.id,
        productTypes:2
      }
      listCourseMedias.setParams(data)
      await listCourseMedias.fetch(function (content) {
        _this.overlay = false;
        _this.medias = content;
      }, function (content) {
        console.log(content)
      });
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param.mediaId
      _this.pictureUrl = param.pictureUrl
    },
    showChangeNameModal(param1, param2) {
      let _this = this;
      _this.namePictureURL = param1;
      _this.newName = param2
    },
    showChangePriorityModal(param1) {
      let _this = this;
      _this.priorityPictureURL = param1;
      _this.newPriority = ''
    },
    select(event) {
      this.file = event.target.files.item(0);
      // this.createChunks();
    },
    upload() {
      axios(this.config).then(response => {

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `فایل با موفقیت آپلود شد.`,
          },
        })

        window.location.reload();

      }).catch(error => {
        this.errorAlert = true;
        console.log(error);
      });
    },
    async deleteMedia(param) {
      let _this = this;
      _this.overlay = true

      let mediaDeleteByPictureUrlRequest = new MediaDeleteByPictureUrlRequest(_this);
      mediaDeleteByPictureUrlRequest.setParams([param]);
      await mediaDeleteByPictureUrlRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async updateMediaName() {
      let _this = this;
      _this.overlay = true

      let data = {
        socialMediaId: _this.namePictureURL,
        newName: _this.newName
      }

      let mediaChangeMediaNameRequest = new MediaChangeMediaNameRequest(_this);
      mediaChangeMediaNameRequest.setParams(data);
      await mediaChangeMediaNameRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async updateMediaPriority() {
      let _this = this;
      _this.overlay = true

      let data = {
        socialMediaId: _this.priorityPictureURL,
        priority: _this.newPriority
      }

      let mediaChangePriorityRequest = new MediaChangePriorityRequest(_this);
      mediaChangePriorityRequest.setParams(data);
      await mediaChangePriorityRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },

  }
}
</script>

<style scoped>

</style>
